import React from 'react'
import styled from 'styled-components'
import {responsiveTitle2Bold} from '../components/typography.module.css'
import {buildImageObj} from '../lib/helpers'
import {Link} from 'gatsby'
import {imageUrlFor} from '../lib/image-url'
import {FiMapPin} from 'react-icons/fi'
import Fade from 'react-reveal/Fade'
import RenderInBrowser from 'react-render-in-browser'

const Wrapper = styled.section`
    display: grid;
    grid-template-rows: 1fr;
    grid-column-gap: 2em;
    /* grid-row-gap: 2em; */
    background-color: #212121;
    overflow:hidden;
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
        transition: 0.2s all cubic-bezier(0.215, 0.61, 0.355, 1);

  :hover img{
    transform:scale(1.03);
  }
  :hover{
    transform:scale(1.03);
    background-color: #212121;
  }
  h2{
    padding: 0.8rem 1rem;
    margin:0;
  @media (max-width: 1070px){
    font-size:1.2rem;
  }
    @media (max-width: 900px){
    font-size:1rem;
    
  }
     @media (max-width: 600px){
    font-size:1rem;
    padding-top: 1rem;
  }
  }
  span{
    margin-right:0.4rem;
  }
  p{
    text-align:left;
    margin: 0;
    padding: 0 0 0.8rem 1rem;
    font-size:var(--font-micro-small);
    @media (max-width: 1070px){
      font-size:0.9rem;
    }
    @media (max-width: 900px){
      font-size:0.8rem;
    }
  }
  @media (max-width:600px){
    h2{
      padding-top: 0;
    }
  }
`
const Image = styled.img`
  width: 100%;
`
function Person (props) {
  const {name, slug, mainImage, location} = props
  return (
    <>
      <RenderInBrowser except safari ie>
        <Fade>
          <Wrapper>
            <Link to={`/team/${slug.current}`}>
              <div>
                <Image
                  src={imageUrlFor(buildImageObj(mainImage))
                    .width(600)
                    .height(600)
                    .fit('crop')
                    .url()}
                  alt={mainImage}
                />
              </div>
              <h2 className={responsiveTitle2Bold}>{name}</h2>
              <p><span><FiMapPin size='0.8em' /></span>{location}</p>
            </Link>
          </Wrapper>
        </Fade>
      </RenderInBrowser>
      <RenderInBrowser only safari ie>
        <div>
          <Wrapper>
            <Link to={`/team/${slug.current}`}>
              <div>
                <Image
                  src={imageUrlFor(buildImageObj(mainImage))
                    .width(600)
                    .height(600)
                    .fit('crop')
                    .url()}
                  alt={mainImage}
                />
              </div>
              <h2 className={responsiveTitle2Bold}>{name}</h2>
              <p><span><FiMapPin size='0.8em' /></span>{location}</p>
            </Link>
          </Wrapper>
        </div>
      </RenderInBrowser>
    </>
  )
}

export default Person
