import React from 'react'
import {Link, graphql} from 'gatsby'
import Container from '../components/container'
import GraphQLErrorList from '../components/graphql-error-list'
import SEO from '../components/seo'
import Layout from '../containers/layout'
import {mapEdgesToNodes, filterOutDocsWithoutSlugs} from '../lib/helpers'
import Hero from '../components/hero'
import Person from '../components/person'
import styled from 'styled-components'
import {responsiveTitle1} from '../components/typography.module.css'

export const query = graphql`
  query TeamQuery {
  site: sanitySiteSettings {
    teamExcerpt
    teamImage {
      crop {
        _key
        _type
        top
        bottom
        left
        right
      }
      hotspot {
        _key
        _type
        x
        y
        height
        width
      }
      asset {
        _id
      }
      alt
    }
    teamFunImage {
      crop {
        _key
        _type
        top
        bottom
        left
        right
      }
      hotspot {
        _key
        _type
        x
        y
        height
        width
      }
      asset {
        _id
      }
      alt
    }
    teamOrder {
      name
      tel
      location
      slug {
        current
      }
      _rawBio
      image {
        crop {
          _key
          _type
          top
          bottom
          left
          right
        }
        hotspot {
          _key
          _type
          x
          y
          height
          width
        }
        asset {
          _id
        }
        alt
      }
      tags {
        _key
        _type
        name
        url
        platform
      }
    }
  }
}

`
const Wrapper = styled.section`
  border-top: 1px solid white;
  padding: 2em 0;
  display: grid;
  margin: 1em 0;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-column-gap: 2em;
  grid-row-gap: 2em;
  @media (max-width: 800px){
    grid-template-columns: 1fr 1fr;
    grid-template-row: 1fr 1fr;
  }
  @media (max-width: 600px){
    grid-template-columns: 1fr ;
    grid-template-row: 1fr 1fr 1fr 1fr;
  }
  `
const StyledLink = styled(Link)`
color:black;
:hover{
  color:black;
}
`
const ArchivePage = props => {
  const {data, errors} = props
  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }
  const site = data.site
  return (
    <Layout>
      <SEO title='Team' description={site.teamExcerpt} />
      <Container>
        <Hero excerpt={site.teamExcerpt} title='Team' mainImage={site.teamImage} secondaryImage={site.teamFunImage} />
        <StyledLink to='/team/'>← Go Back</StyledLink>
        <Wrapper>
          {
            site.teamOrder.map(person => (
              <Person tags={person.tags} key={person.id} location={person.location} _rawBody={person._rawBody} mainImage={person.image} name={person.name} slug={person.slug} />
            ))
          }
        </Wrapper>
      </Container>
    </Layout>
  )
}

export default ArchivePage
